@import '../../../styles/customMediaQueries.css';
.root {
  display: flex;
  flex-direction: column;
  position: relative;
}

.searchOptions {
  display: flex;
  flex-direction: row;
  margin-block-end: 8px;
}

.searchResultSummary {
  font-size: 16px;
  font-weight: var(--fontWeightRegular);

  margin-block-start: 10px;
  margin-block-end: 11px;
  margin-inline-start: 0px;
  margin-inline-end: 8px;
  @media (--viewportMedium) {
    font-size: 18px;
  }
}

.loadingResults {
  font-size: 16px;
  font-weight: var(--fontWeightRegular);
  /* Cover parent element's space*/
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;

  /* Layout */
  padding: 9px 0 0 0;
  margin: 0;
  @media (--viewportMedium) {
    font-size: 18px;
  }
}

.resultsFound {
  white-space: nowrap;
}

.sortyByWrapper {
  display: inline-flex;
  margin-inline-start: auto;
}

.sortyBy {
  composes: marketplaceSmallFontStyles from global;
  font-weight: var(--fontWeightRegular);
  padding-block-start: 8px;
  padding-inline-end: 8px;
  padding-block-end: 8px;
  padding-inline-start: 0px;
}
