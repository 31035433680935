@import '../../../styles/customMediaQueries.css';

.root {
  position: relative;
  padding-block-start: 24px;
  margin-block-start: 12px;
  padding-block-end: 17px;
  border-block-end: 1px solid var(--colorGrey100);

  @media (--viewportMedium) {
    padding-block-start: 16px;
    padding-block-end: 0;
    border-block-end: 0;
  }
}

.contentWrapper {
  display: flex;
  flex-wrap: wrap;
  max-width: 300px;
  padding-block-start: 5px;
  padding-block-end: 5px;
  margin-block-start: 0px;

  @media (--viewportMedium) {
    padding-block-start: 0px;
    padding-block-end: 8px;
    margin-block-start: 0px;
  }
}

.formWrapper {
  padding-inline-start: 12px;
}

.contentWrapperSidebar {
  margin-block-start: 12px;
}

.labelPopup {
  composes: h5 from global;

  font-weight: var(--fontWeightSemiBold);

  padding-block-start: 11px;
  padding-inline-end: 0;
  padding-block-end: 13px;
  padding-inline-start: 0;

  margin-block-start: 0;
  margin-inline-end: 0;
  margin-block-end: 18px;
  margin-inline-start: 0;
}
@media (--viewportMedium) {
  .label {
    padding-block-start: 8px;
    padding-inline-end: 0;
    padding-block-end: 16px;
    padding-inline-start: 0;
  }
}

.inputsWrapper {
  display: flex;

  @media (--viewportMedium) {
    padding-block-end: 5px;
  }
}

.minValue,
.maxValue {
  width: 48px;
  text-align: center;

  -moz-appearance: textfield;

  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  @media (--viewportMedium) {
    padding-block-start: 3px;
    padding-inline-end: 0;
    padding-block-end: 3px;
    padding-inline-start: 0;
  }
}

.valueInSidebar {
  width: 72px;
}

.valueSeparator {
  margin-block-start: 6px;
  margin-inline-end: 8px;
  margin-block-end: 0;
  margin-inline-start: 8px;
  @media (--viewportMedium) {
    margin-block-start: 5px;
    margin-inline-end: 8px;
    margin-block-end: 0;
    margin-inline-start: 8px;
  }
}

.sliderWrapper {
  display: flex;
  padding-block-start: 17px;
  padding-inline-end: 0;
  padding-block-end: 25px;
  padding-inline-start: 0;
  @media (--viewportMedium) {
    padding-block-start: 3px;
    padding-inline-end: 0;
    padding-block-end: 13px;
    padding-inline-start: 0;
  }
}
