@import '../../../styles/customMediaQueries.css';

.sortBy {
  margin-inline-end: 9px;
}

.sortByDesktop {
  margin-inline-start: auto;
}

.sortByMenuLabel {
  display: inline-block;
  width: auto;
  height: 35px;
  min-height: 35px;

  padding: 0 18px;
  margin-block-start: 0;
  margin-inline-end: 9px;
  margin-block-end: 0;
  margin-inline-start: 0;

  transition: all var(--transitionStyleButton);
  cursor: pointer;

  /* Font */
  font-weight: var(--fontWeightSemiBold);
  line-height: 20px;

  /* Button text styles */
  /* Default button text is centered without decorations */
  text-align: center;
  text-decoration: none;

  /* Button borders */
  border: 1px solid var(--colorGrey100);
  border-radius: var(--borderRadiusMedium);

  background-color: var(--colorWhite);
  font-size: 16px;
  @media (--viewportMedium) {
    font-size: 18px;
  }

  &:focus,
  &:hover {
    outline: none;
    box-shadow: var(--boxShadowFilterButton);
    text-decoration: none;
    border-color: var(--colorGrey300);
  }

  &:disabled {
    box-shadow: none;
    cursor: not-allowed;
  }

  @media (--viewportMedium) {
    height: auto;
    min-height: 0;
    padding: var(--marketplaceButtonSmallDesktopPadding);
    margin: 0;

    font-weight: var(--fontWeightMedium);

    &:hover,
    &:focus {
      border-color: transparent;
    }
  }
}
