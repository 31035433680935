@import '../../../styles/customMediaQueries.css';

.noSearchResults {
  font-size: 16px;

  /* Reserves 48px of vertical space */
  line-height: 24px;
  margin-block-start: 18px;
  margin-block-end: 18px;

  @media (--viewportMedium) {
    font-size: 18px;
    margin-block-start: 8px;
    margin-block-end: 8px;
  }
}

.createListingLink,
.resetAllFiltersButton {
  font-weight: var(--fontWeightMedium);
  color: var(--marketplaceColor);

  /* Layout */
  display: inline;
  margin: 0;
  padding: 0;

  /* Override button styles */
  outline: none;
  text-align: start;
  border: none;
  cursor: pointer;
  font-size: 16px;

  &:focus,
  &:hover {
    color: var(--colorGrey700);
  }

  @media (--viewportMedium) {
    padding: 3px 0 5px 0;
    font-size: 18px;
  }
}
